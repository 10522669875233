import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('jupiter-terminal')
export class JupiterTerminal extends LitElement {

    static override styles = css`
    /* Add any custom styles for your web component here */
  `;

    override firstUpdated() {
        let jup :any = window.Jupiter;
        // Initialize the Jupiter widget
        if (jup) {
            jup.init({
                displayMode: "widget",
                endpoint: "https://api.testnet-beta.solana.com",
                defaultExplorer: "SolanaFM",
                formProps: {
                    fixedOutputMint: true,
                    swapMode: "ExactIn",
                    initialAmount: "100",
                    initialOutputMint: "DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263",
                },
            });
        }
    }

    override render() {
        return html`
      <div id="jupiter-terminal"></div>
    `;
    }
}